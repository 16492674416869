import React from "react";
import { Link, graphql } from "gatsby";
import parse from "html-react-parser";

//import { isEmpty } from 'lodash';
import Layout from "../components/layout";
import Seo from "../components/seo";
//import { GatsbyImage, getImage } from "gatsby-plugin-image"

const BlogIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes;

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <Seo title="All posts" />

        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    );
  }

  return (
    <Layout isHomePage>
      <Seo title="All posts" />

      <ol style={{ listStyle: `none` }}>
        {posts.map((post) => {
          const title = post.title;

          // const featuredImage = {
          //   fluid: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
          //   alt: post.featuredImage?.node?.alt || ``,
          // }
          // const image = !!featuredImage?.fluid ? getImage(post.featuredImage.node.localFile.childImageSharp.gatsbyImageData) : null
          //console.log("image=", image, "featuredImage=", featuredImage)

          let postExcerpt = post.excerpt;
          //const removeStr = '<a href="/[^>]*> » Read more '
          const removeStr = '<a href="/[^>]*>&nbsp;&raquo;&nbsp;Read more '
          const regex = new RegExp(removeStr, 'g') // correct way

          const matchedLink = postExcerpt.match(regex);
          //console.log("matchedstring = ", matchedLink);
          let textToReplace1 = !!matchedLink ? matchedLink[0].replace("/", "/web-hosting-blog/index.php/") : "";



          const removeStr2 = '<a href="/[^>]*> » Read more '
          const regex2 = new RegExp(removeStr2, 'g') // correct way
          const matchedLink2 = postExcerpt.match(regex2);
          //console.log("matchedstring2 = ", matchedLink2);
          let textToReplace2 = !!matchedLink2 ? matchedLink2[0].replace("/", "/web-hosting-blog/index.php/") : "";



          //postExcerpt = !!textToReplace1 ? postExcerpt.replace(regex, textToReplace1)  : (!!textToReplace2 ? postExcerpt.replace(regex, textToReplace2)    : postExcerpt) // it works

          postExcerpt = !!textToReplace1 ? postExcerpt.replace(regex, textToReplace1)
            : !!textToReplace2 ? postExcerpt.replace(regex, textToReplace2) : postExcerpt // it works

          //console.log("replaced postExcerpt=", postExcerpt)


          // const featuredImage = {
          //   fluid: post.featuredImage?.node?.localFile?.childImageSharp?.fluid,
          //   alt: post.featuredImage?.node?.alt || ``,
          // }

          return (
            <li key={post.uri}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header>
                  <h2>
                    <Link to={"/web-hosting-blog/index.php" + post.uri} itemProp="url">
                      <span itemProp="headline">{parse(title)}</span>
                    </Link>
                  </h2>
                  <small>{post.date}</small>
                </header>
                <section itemProp="description">

            {/*       {featuredImage?.fluid && (
                    <GatsbyImage image={image} alt={featuredImage.alt} style={{float: "left", margin: "12px",}} />
                  )} */}
                
                    {
                      parse(postExcerpt)

                    }
                  
                </section>
              </article>
            </li>
          );
        })}
      </ol>

      {previousPagePath && (
        <>
          <Link to={previousPagePath}>Previous page</Link>
          <br />
        </>
      )}
      {nextPagePath && <Link to={nextPagePath}>Next page</Link>}
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query WordPressPostArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt

      }
    }
  }
`;


// featuredImage {
//   node {
//     altText
//     localFile {
//       childImageSharp {
//         gatsbyImageData(
//           quality: 90
//           width: 150
//           formats: [AUTO, WEBP, AVIF]
//         )
        
//       }
//     }
//   }
// }